
.blue {
        color: blue;
}
.by-img-uploader .avatar-uploader-icon.coverImg, .coverImg {
        width: 160px;
        height: 120px;
        line-height: 120px;
}
.by-img-uploader .avatar-uploader-icon.smallCoverImg,
    .smallCoverImg {
        width: 120px;
        height: 120px;
        line-height: 120px;
}
.img-uploader-container .imgList {
        width: 160px;
        height: 120px;
}
.by-goods-create .cate-prop-item {
        width: 30%;
        float: left;
}
