
.extra_info .el-form-item__label {
        font-size: 12px;
        line-height: 20px;
        width: 140px;
}
.extra_info .el-form-item {
        margin-bottom: 0px;
}
.extra_info .el-form-item__content {
        line-height: 20px;
        font-size: 12px;
}
