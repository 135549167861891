
.read{
        color: #999999;
}
.read .el-button--text {
        color: #999999;
}
.message-time {
        text-align: center;
}
.el-dialog--center .el-dialog__body {
        padding: 5px;
}

