
.by-img-uploader .photo {
        width: 120px;
}
.by-img-uploader .img-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        line-height: 0px;
        position: relative;
        overflow: hidden;
}
.by-img-uploader .img-uploader .el-upload:hover {
        border-color: #409EFF;
}
.by-img-uploader .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
}
.by-img-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
}
.by-img-uploader .preview-img {
        width: 178px;
        height: 178px;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 5px;
}
.by-img-uploader .uploadedimg {
        padding: 5px;
        border: 1px solid #666666;
        text-align: center;
}
.by-img-uploader .uploadedimg .el-image.actived {
        border: 5px solid rgb(148, 63, 63);
}
.by-img-uploader .uploadedimg .el-image {
        border: 1px solid #777777;
        margin-right: 5px;
}
.by-img-uploader .img-uploader-container {
        text-align: left;
}
.by-img-uploader .img-uploader-container .img-item {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin: 0 auto;
}
.by-img-uploader .img-uploader-container .remove {
        position: absolute;
        top: 5px;
        right: -5px;
        font-size: 25px;
        color: red;
        background: #FFF;
}
