
.not-found0-404{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        font-size: 30px;
}
.content-wrapper .not-found0-404{
        height: calc(100vh - 101px);
}
