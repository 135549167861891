
.read {
        color: #999999;
}
.read .el-button--text {
        color: #999999;
}
.message-time {
        text-align: center;
}
.el-dialog--center .el-dialog__body {
        padding: 5px;
}
.custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
}
.el-tree-node__content {
        margin-top: 4px;
}
