
.sku-panel {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
}
.sku-panel .sku-item {
        width: 160px;
}
.by-goods-sku .goods-img {
        border-radius: 0;
        width: 80px;
        height: 80px;
}
.goodsImg {
        width: 160px;
        height: 120px;
}
