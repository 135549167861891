
.by-img-uploader .avatar{
        border-radius: 5px;
        margin: 0px;
}
.el-tag + .el-tag {
        margin-left: 10px;
}
.button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
}
.input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
}
